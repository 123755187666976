import { firestore } from '@/helpers/firestore'
import { COLLECTIONS_ENUM } from '@/enums'

export const isHiringActive = async ({ ownerId, scenarioId }) => {
  if (ownerId === undefined || scenarioId === undefined) {
    return false
  }

  const owner = await firestore
    .collection(COLLECTIONS_ENUM.OWNERS)
    .doc(ownerId)
    .collection(COLLECTIONS_ENUM.SCENARIO_PUBLIC)
    .doc(scenarioId)
    .get()

  const { isActive } = owner.data()

  return isActive === 'ON'
}

export const updateHiringActive = async ({ ownerId, scenarioId, isHiringActive }) => {
  await firestore
    .collection(COLLECTIONS_ENUM.OWNERS)
    .doc(ownerId)
    .collection(COLLECTIONS_ENUM.SCENARIO_PUBLIC)
    .doc(scenarioId)
    .set({ isActive: isHiringActive ? 'ON' : 'OFF' }, { merge: true })
}

export const getFans = async ({ ownerId, ownerScenarioId }) => {
  let fans = await firestore
    .collection(COLLECTIONS_ENUM.OWNERS)
    .doc(ownerId)
    .collection(COLLECTIONS_ENUM.SCENARIO_PUBLIC)
    .doc(ownerScenarioId)
    .collection('RESPONSES')
    .where('optInEmail', '==', 'OK')
    .get()

  fans = fans.docs.map(d => {
    return {
      ...d.data(),
      createdAt: d.data().createdAt.toDate()
    }
  })

  return fans
}
