<template>
  <v-card>
    <v-card-title>{{$t('m.fans.title')}}</v-card-title>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="fans"
        sort-by="created"
        sort-desc
        :loading="isLoading"
      >
        <template v-slot:top>
          <v-row justify="end">
            <v-col cols="auto">
              <v-btn @click="send" :disabled="isHiringActive">
                {{$t('m.fans.send')}}
                <v-icon right>mdi-send</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </v-data-table>
    </v-card-text>
    <v-dialog v-model="dialog">
      <v-card>
        <v-card-title>{{$t('m.fans.dialog.title')}}</v-card-title>
        <v-card-text>{{$t('m.fans.dialog.text')}}</v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="sendCancel">{{$t('m.cancel')}}</v-btn>
          <v-btn text @click="sendOK">{{$t('m.ok')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
export default {
  name: 'fans',
  props: {
    fans: {
      type: Array,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: true
    },
    isHiringActive: {
      type: Boolean,
      required: true
    }
  },
  data: function () {
    return {
      headers: [
        {
          text: this.$i18n.t('m.fans.table.created'),
          align: 'start',
          sortable: true,
          value: 'createdAt'
        },
        {
          text: this.$i18n.t('m.fans.table.name'),
          align: 'start',
          sortable: true,
          value: 'applicantName'
        },
        {
          text: this.$i18n.t('m.fans.table.contact'),
          align: 'start',
          sortable: true,
          value: 'contactInfo'
        }
      ],
      dialog: false
    }
  },
  methods: {
    send () {
      this.dialog = true
    },
    sendCancel () {
      this.dialog = false
    },
    sendOK () {
      this.dialog = false
      this.$emit('send')
    }
  }
}
</script>
