<template>
  <v-container fluid>
    <fans
      :fans="fans"
      :isLoading="isLoading"
      :isHiringActive="isHiringActive_"
      @send="onSend"
    />
  </v-container>
</template>

<script>
import format from 'date-fns/format'
import fans from '@/components/fan/fans'
// import { getFans, isHiringActive, updateHiringActive } from '@/helpers/firebase-functions'
// import { isHiringActive, updateHiringActive } from '@/helpers/firebase-functions'
import { EVENT_NAMES, analyticsEvent } from '@/helpers/analytics'
import { isHiringActive, updateHiringActive, getFans } from '@/helpers/fans'

export default {
  components: {
    fans
  },
  data: () => ({
    fans: [],
    isLoading: false,
    isHiringActive_: false
  }),
  methods: {
    async onSend () {
      await updateHiringActive({ ownerId: this.ownerId, scenarioId: this.ownerScenarioId, isHiringActive: true })
      this.isHiringActive_ = await isHiringActive({ ownerId: this.ownerId, scenarioId: this.ownerScenarioId })
    }
  },
  async mounted () {
    analyticsEvent(EVENT_NAMES.LOADED_FANS)
    this.isLoading = true

    this.ownerId = this.$route.params.ownerId
    this.ownerScenarioId = process.env.VUE_APP_RECRUIT_SCENARIO_ID

    const [ fans, isHiringActive_ ] = await Promise.all([
      getFans({ ownerId: this.ownerId, ownerScenarioId: this.ownerScenarioId }),
      isHiringActive({ ownerId: this.ownerId, scenarioId: this.ownerScenarioId })
    ])

    this.fans = fans.map(fan => {
      let { createdAt, applicantName, wayToCommunicateToApplicant, applicantEmail, applicantPhone } = fan
      wayToCommunicateToApplicant = wayToCommunicateToApplicant || 'Email' // workaround for old data
      return {
        createdAt: format(new Date(createdAt), 'yyyy/MM/dd HH:mm'),
        applicantName,
        contactInfo: wayToCommunicateToApplicant === 'Email' ? applicantEmail : applicantPhone
      }
    })
    this.isHiringActive_ = isHiringActive_

    this.isLoading = false
  }
}
</script>
